/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('mobile-check-load', {
    parent: 'root',
    url: '/mcl',
    templateUrl:
      'sections/mobile-check-load/templates/acp-mobile-check-load.ng.html',
    controller: 'AcpMobileCheckLoadCtrl',
    data: {
      permissions: {
        only: ['mcl_available'],
        except: [
          'isCIPConditionalDocumentsNotUploaded',
          'isCIPConditionalDocumentsUploaded'
        ],
        redirectTo: {
          mcl_available: 'dashboard',
          isCIPConditionalDocumentsNotUploaded: 'dashboard',
          isCIPConditionalDocumentsUploaded: 'dashboard'
        }
      }
    },
    resolve: {
      module: /* @ngInject */ function ($ocLazyLoad) {
        return import(
          /* webpackChunkName: "acp.section.mobile-check-load" */ './index.js'
        ).then($ocLazyLoad.loadModule);
      }
    }
  });
}

export default states;
